// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-neuerscheinungen-js": () => import("./../../../src/pages/neuerscheinungen.js" /* webpackChunkName: "component---src-pages-neuerscheinungen-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-rezension-js": () => import("./../../../src/pages/rezension.js" /* webpackChunkName: "component---src-pages-rezension-js" */),
  "component---src-pages-ubermich-js": () => import("./../../../src/pages/ubermich.js" /* webpackChunkName: "component---src-pages-ubermich-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-autor-js": () => import("./../../../src/templates/autor.js" /* webpackChunkName: "component---src-templates-autor-js" */),
  "component---src-templates-rezension-js": () => import("./../../../src/templates/rezension.js" /* webpackChunkName: "component---src-templates-rezension-js" */)
}

